import React, { useEffect, useState } from "react";
import { getHygraphSubPage1Data } from "../../services/HygraphService";
import { Widget } from "@typeform/embed-react";
import AnimationButton from "../AnimationButton/AnimationButton";
import "./firedUpCareer.scss";

function SubPageContent({ content }) {
  const { subTitleText, subTitleSecondDescription, label, bannerTitleText, contentImage } = content;

  const [subTitleDescription, setSubTitleDescription] = useState(null);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const firedUpData = await getHygraphSubPage1Data("Fired Up Career");

        setSubTitleDescription(firedUpData.mainDescription);
      } catch (error) {}
    }

    fetchData();
  }, []);

  function onClick(event) {
    event.preventDefault();
    setClicked(true);
  }

  return (
    <section className="content-container subpage-role-description">
      {clicked ? (
        <Widget id="XgWzg2xo" className="typeform-widget-form" />
      ) : (
        <>
          <div>
            <h2>{subTitleText}</h2>
            {subTitleDescription && <p>{subTitleDescription}</p>}
            <br />
            <p>{subTitleSecondDescription}</p>
            <AnimationButton
              onClick={onClick}
              label={label}
              className={bannerTitleText.includes("Head Office") ? "head-office" : ""}
            />
          </div>
          <div className="desktop-only">
            <img src={contentImage} aria-label="contentImage" />
          </div>
        </>
      )}
    </section>
  );
}

export default SubPageContent;

import React, { useEffect, useState } from "react";
import "./TestimonialSection.scss";
import classNames from "classnames";
import { getTestimonialData } from "../../services/HygraphService";
import rightPattern from "../../assets/img/patterns/Testimonial_Right_Pattern.svg";
import leftPattern from "../../assets/img/patterns/Testimonial_Left_Pattern.svg";
import leftPatternMobile from "../../assets/img/patterns/Testimonial_Left_Pattern_Mobile.svg";
import rightPatternMobile from "../../assets/img/patterns/Testimonial_Right_Pattern_Mobile.svg";

const HygraphTestimonialResponse = {};

const TestimonialSection = ({
  rect = false,
  bottomRect = false,
  topRect = false,
  className,
  pageTitle,
}) => {
  const [testimonialContent, setTestimonialContent] = useState(HygraphTestimonialResponse);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTestimonialData(pageTitle);
      setTestimonialContent({
        ...HygraphTestimonialResponse,
        title: data.title,
        description: data.description.text,
        signature: data.signature,
        imageURL: data.image.url,
      });
    };
    fetchData();
  }, []);

  return (
    <div
      className={classNames("testimonial-section-container", className, {
        rect,
        "bottom-rect": bottomRect,
        "top-rect": topRect,
      })}
    >
      <div className="restricted-content-container">
        <div className="testimonial-section">
          <div className="image-container">
            <img className="testimonial-img" src={testimonialContent.imageURL} alt="Testimonial" />
          </div>
          <div className="testminonial-text">
            <h3>{testimonialContent.title}</h3>
            <p className="testimonial">{testimonialContent.description}</p>
            <p className="author">{testimonialContent.signature}</p>
          </div>
          <img
            src={rightPattern}
            aria-label="right-pattern-testimonial"
            className="right-pattern-testimonial no-pointer"
          />
          <img
            src={leftPattern}
            aria-label="left-pattern-testimonial"
            className="left-pattern-testimonial no-pointer"
          />
          <img
            src={leftPatternMobile}
            aria-label="left-pattern-testimonial-mobile"
            className="left-pattern-testimonial-mobile no-pointer"
          />
          <img
            src={rightPatternMobile}
            aria-label="right-pattern-testimonial-mobile"
            className="right-pattern-testimonial-mobile no-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
